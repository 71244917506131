<template>
    <div v-if="params.data.episode_id">
       <a :href="'https://www.ncdc.noaa.gov/stormevents/eventdetails.jsp?id='+params.data.event_id" target="_blank">
       <i class="flaticon2-list-2 text-primary cursor-pointer"></i>
     </a>
    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {
          openDownload(){
           this.params.openDownload(this.params.data);
          },
        }
    }
</script>

