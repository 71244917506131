<template>
  <!--begin::Advance Table Widget 2-->
  <div>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Map </span>
      </h3>
      <div class="card-toolbar d-flex" data-app>
        <v-autocomplete
          style="width: 450px"
          v-model="currentLocation"
          :items="locations"
          :loading="isLoading"
          :search-input.sync="search"
          clearable
          item-text="text"
          label="Search Address"
          return-object
        >
        </v-autocomplete>
      </div>
    </div>
    <div class="card-body pt-3 pb-0 map-card-body pb-5">
      <div id="viewDiv" ref="map" class="balt-theme"></div>
    </div>
  </div>
  </div>
</template>
<style>
@import url("https://js.arcgis.com/4.26/@arcgis/core/assets/esri/themes/light/main.css");
</style>
<script>

import {mapGetters, mapState} from "vuex";
import {
  loadModules
} from 'esri-loader';

import {GET_ADDRESS_SUGGESTIONS, GET_INFORMATION_FROM_ADDRESS} from "@/core/services/store/project.module";


export default {
  name: "map-widget",
  data() {
    let projectLocation = null;
    let center = [103.811279, 1.345399];
    if(this.$parent.project.loss_full_location)

    if(this.$parent.project.loss_lat) {
       center = [this.$parent.project.loss_lng, this.$parent.project.loss_lat];
       projectLocation = this.$parent.project.loss_full_location;
    }
    return {
      isLoading: false,
      locations: [],
      currentLocation: projectLocation,
      projectLocation: projectLocation,
      search: null,
      center: center,
      map: {},
      view: null,
    };
  },
  components: {
  },
  methods: {
    getAddressSuggestionsfromApi(text){
       this.$store
          .dispatch(GET_ADDRESS_SUGGESTIONS, {text: text})
          .then((data) => {
             this.locations = data;
          })
    },
    setCurrentAddress(){
         this.$store
          .dispatch(GET_ADDRESS_SUGGESTIONS, {text: this.projectLocation})
          .then((data) => {
             this.locations = data;
             if(this.locations.length > 0)
               this.currentLocation = data[0];
             this.search = this.projectLocation;
          })
    },
    getAttributesFromAddress(){
         this.$store
          .dispatch(GET_INFORMATION_FROM_ADDRESS, {text: this.currentLocation.text})
          .then((data) => {
             if(data.lat){
                this.center = [data.lng, data.lat];
                this.view.center = this.center;
                this.view.goto({
                  center: this.center
                });
             }
          })
    },
    loadMap() {
      loadModules(['esri/Map', 'esri/views/MapView'], {
            css: true
          })
          .then(([ArcGISMap, MapView]) => {
            // create map with the given options
            const map = new ArcGISMap({
              basemap: "satellite"
            });
            // assign map to this view
            this.view = new MapView({
              container: this.$refs.map,
              map: map,
              center: this.center,
              zoom: 20
            });
          });
      }
  },
  mounted() {
    this.loadMap();
    if(this.projectLocation){
      this.setCurrentAddress();
    }
  },
   watch: {
    currentLocation(){
      this.getAttributesFromAddress()
    },
    search (val) {
      if(this.search)
        this.getAddressSuggestionsfromApi(this.search)
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  }
};
</script>
<style>
.map-card-body {
  max-height: 600px !important;
}
#viewDiv {
  width: 100%;
  height: 400px;
}
</style>
