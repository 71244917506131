<template>
    <div v-if="params.data.name.includes('(NOAA)')">
       <a  @click="openDownload" >
       <i class="flaticon2-download text-primary cursor-pointer"></i>
     </a>
    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {
          openDownload(){
           this.params.openDownload(this.params.data);
          },
        }
    }
</script>

