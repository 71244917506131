<template>
  <div>
      <div class="row">
         <div class="col-3">
           <ProjectOverviewWidget v-if="project && can_use"></ProjectOverviewWidget>
         </div>
      <div class="col-9">
        <MapWidget
            v-if="project && can_use"
            @update-project="updateProject"
        >
        </MapWidget>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="Weather" active><WeatherWidget v-if="project && can_use"></WeatherWidget></b-tab>
            <b-tab title="Storm" ><StormWidget v-if="project && can_use"></StormWidget></b-tab>
            <b-tab title="BuildFax" ><BuildFaxWidget v-if="project && can_use"></BuildFaxWidget></b-tab>
            <b-tab title="Generate Doc" ><GenerateDoc v-if="project && can_use"></GenerateDoc></b-tab>
          </b-tabs>
        </div>
        <b-modal ref="loss-date-modal" hide-footer title="Input Loss Date">
          <v-row>
            <v-col cols="10" offset="1">
              <p class="center">Currently Loss Date is not defined or can't parse from teamwork project. Please define before using this project!</p>
              <label>Loss Date:</label>
               <b-form-datepicker
                   class="mr-2"
                   v-model="formData.loss_date"
                   placeholder="Loss Date"
                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
               >
               </b-form-datepicker>
              <div v-if="errors && errors.loss_date && errors.loss_date.length >0">
                <span class="text-danger" v-for="(error, i) in errors.loss_date">{{ error }}</span>
              </div>
            </v-col>
          </v-row>
      <v-row>
      <v-col cols="10" offset="1" style="text-align: right">
         <b-button variant="primary" pill :disabled="!formData.loss_date" @click="saveLossDate">Submit</b-button>
      </v-col>
    </v-row>
   </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {UPDATE_ACCOUNT_INFO} from "@/core/services/store/profile.module";
import MapWidget from "@/view/content/widgets/MapWidget.vue";
import WeatherWidget from "@/view/content/widgets/WeatherWidget.vue";
import StormWidget from "@/view/content/widgets/StormWidget.vue";
import BuildFaxWidget from "@/view/content/widgets/BuildFaxWidget.vue";
import GenerateDoc from "@/view/content/widgets/GenerateDoc.vue";
import ProjectOverviewWidget from "@/view/content/widgets/ProjectOverviewWidget.vue";

import {
  GET_TEAMWORK_PROJECT,
  UPDATE_PROJECT
} from "../../core/services/store/project.module";

import Swal from "sweetalert2";

export default {
  name: "project-detail",
  components: {
     MapWidget,
     WeatherWidget,
     StormWidget,
     BuildFaxWidget,
     GenerateDoc,
     ProjectOverviewWidget,
  },
 data() {
    return {
      project_id : this.$route.params.id,
      project: null,
      can_use: false,
      formData: {

      }
    }
  },
  mounted() {
    if(!this.currentUserAccountInfo.username){
      this.uploadAccount();
    }
  },
  methods: {
     getProject(){
        this.$store
          .dispatch(GET_TEAMWORK_PROJECT, {project_id :this.project_id})
          .then((data) => {
            if (data.status){
              this.project = data.data;
              if(this.project.loss_date)
              {
                this.can_use = true;
              }
              else{
                this.can_use = false;
                 this.$refs['loss-date-modal'].show()
              }
            }
            else
            {
               Swal.fire({
                 title: "Error",
                 text: data.msg,
                 icon: "error",
                 confirmButtonClass: "btn btn-secondary"
               });
                console.error(error);
            }
          });
     },
    updateProject(data){
      if(data.zipcode) this.project.zipcode = data.zipcode;
      if(data.county) this.project.county = data.county;
      if(data.state) this.project.state = data.state;
      if(data.lat) this.project.lat = data.lat;
      if(data.lng) this.project.lng = data.lng;
    },
    saveLossDate(){
         this.$store
          .dispatch(UPDATE_PROJECT, {
            pk: this.project.project_id,
            loss_date: this.formData.loss_date
          })
             .then((data) => {
               this.$refs['loss-date-modal'].hide()
               window.location.reload();
             })
    },
     uploadAccount(){
      this.$store
          .dispatch(UPDATE_ACCOUNT_INFO, )
          .then(() => {});
    },
  },
  created() {
    this.getProject()
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo"]),
  },
};
</script>
