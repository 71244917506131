<template>
  <div class="card card-custom  gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Storm </span>
      </h3>
      <div class="card-toolbar" >
        <div class="d-flex card-toolbar-date">
          <div class="w-100"></div>
          <b-form-datepicker
              class="mr-2"
              v-model="formData.loss_start_date"
              placeholder="Start Date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          >

          </b-form-datepicker>
          <b-form-datepicker
              class="mr-2"
              v-model="formData.loss_end_date"
              placeholder="End Date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          >
          </b-form-datepicker>
          <b-form-input class="mr-2" type="number" v-model="formData.radius"  placeholder="Radius(miles)" ></b-form-input>
          <button
              @click="getData()"
              :disabled="!(formData.loss_start_date && formData.loss_end_date && formData.radius)"
              class="btn btn-primary font-weight-bold text-uppercase"
          >
            Run
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 pb-3">
      <ag-grid-vue
                class="ag-theme-material ag-grid-vue"
                :columnDefs="columnDefs"
                :masterDetail="false"
                :pagination="false"
                :detailRowAutoHeight="true"
                :detailCellRendererParams="detailCellRendererParams"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                @first-data-rendered="onFirstDataRendered"
                :frameworkComponents="frameworkComponents"
                :rowData="items"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridVue } from 'ag-grid-vue';
import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';

LicenseManager.setLicenseKey("Servoy_B.V_Servoy_7Devs_200Deployment_11_October_2020__MTYwMjM3MDgwMDAwMA==26d908e26f73b44683ba7a5dfdd00755");

import {
  GET_STORM_DATA,
} from "../../../core/services/store/project.module";
import Swal from "sweetalert2";
import StormOpenRenderer from "@/view/content/widgets/datatable/Render/StormOpenRenderer";


export default {
  name: "Weather-widget",
  data() {
    return {
     formData:{
        project_id: null,
        loss_start_date: null,
        radius: null,
        loss_end_date: null,
     },
     gridApi: null,
     columnApi: null,
     stationData : [],
     project: this.$parent.$parent.$parent.project,
     items: [],
     detailCellRendererParams: null,
     defaultColDef: {
       sortable: true,
       suppressMenu: true,
       flex: 1,
       cellStyle: {
         textAlign: 'center',
       }
     },
     frameworkComponents : null,
    };
  },
  components: {
    AgGridVue
  },
  methods: {
    openDownload(data){
      let noaa_id = 'WBAN:'+ data.id.substring(data.id.length-5, data.id.length);
      let url = 'https://www.ncdc.noaa.gov/cdo-web/quickdatapdf/'+noaa_id+'_'+this.project['loss_date']+'.pdf?datasetId=LCD&productId=LCD_FORM&stationId='+noaa_id+'&year='+this.project['loss_date'].substring(0, 4)+'&month='+this.project['loss_date'].substring(5, 7)+'&day='+this.project['loss_date'].substring(8, 10);
      window.open(url, '_blank');
     },
     onFirstDataRendered(params) {
      setTimeout(function () {
        params.api.forEachNode(function (node) {
          node.setExpanded(true);
        });
      }, 0);
     },
     autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.getOriginal_data();
       },
    getData(){
      this.gridApi.showLoadingOverlay();
      this.formData.project_id = this.project.project_id;
      this.formData.state = this.project.loss_state;
      this.formData.county = this.project.loss_county;
      this.formData.long = this.project.loss_lng;
      this.formData.lat = this.project.loss_lat;

      this.$store
          .dispatch(GET_STORM_DATA, this.formData)
          .then((data) => {
            this.gridApi.hideOverlay();
            let result = JSON.parse(data)
            if (result.status){
              this.items = result.data;
            }
            else
            {

            }
          });
    },
    getOriginal_data(){
      this.gridApi.showLoadingOverlay();
      this.$store
          .dispatch(GET_STORM_DATA, { 'project_id': this.project.project_id })
          .then((data) => {
            this.gridApi.hideOverlay();
            let result = JSON.parse(data)
            if (result.status){
              this.items = result.data;
            }
          });
    }
  },

  beforeMount() {
     this.frameworkComponents = {
       'stormOpenRenderer': StormOpenRenderer,
     };
  },
  mounted() {
    if(this.project['loss_start_date'] && this.project['loss_end_date']){
      this.formData.loss_start_date = this.project['loss_start_date'];
      this.formData.loss_end_date = this.project['loss_end_date'];
    }
    this.formData.radius = 20;
  },
  created() {
    this.detailCellRendererParams = {
     detailGridOptions: {
        columnDefs: [
          { headerName: 'Time', field: 'time', minWidth:150},
          { headerName: 'TAVG', field: 'tavg', },
          { headerName: 'TMIN', field: 'tmin'},
          { headerName: 'TMAX', field: 'tmax'},
          { headerName: 'PRCP', field: 'prcp', },
          { headerName: 'WDIR', field: 'wdir',},
          { headerName: 'WSPD', field: 'wspd',},
          { headerName: 'WPGT', field: 'wpgt',},
          { headerName: 'PRES', field: 'pres',},
        ],
        defaultColDef: {
          sortable: false,
          flex: 1,
          cellStyle: {
           textAlign: 'center',
         },
          suppressMenu: true,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.data);
      },
    };

  },
   watch: {
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    columnDefs(){
      return  [
       {
         headerName:'Location',
         field: 'cz_name',
         // cellRenderer: 'agGroupCellRenderer',
         minWidth: 300,
         cellStyle: {
           fontWeight: 500,
           textAlign: 'center',
         },
       },
       {
         headerName: 'Event Type',
         field: 'event_type',
       },
        {
         headerName: 'Magnitude',
         field: 'magnitude',
       },
        {
         headerName: 'Begin Location',
         valueFormatter: BeginLocationFormatter,
       },
        {
         headerName: 'End Location',
         valueFormatter: EndLocationFormatter,

       },
        {
           wrapText: true,
          autoHeight: true,
          minWidth: 450,
          cellStyle: {
          lineHeight: '20px',
          fontSize: '13px',
          paddingBottom: '10px'
         },
         headerName: 'Episode Narrative',
         field: 'episode_narrative',
       },
        {
          headerName: 'Open',
          cellRenderer: 'stormOpenRenderer',

        }
     ];
    },
  }
};

window.BeginLocationFormatter = function BeginLocationFormatter(params) {
  if(params.data.begin_lat) return params.data.begin_lat + ', ' + params.data.begin_lng
  return '-';
};

window.EndLocationFormatter = function EndLocationFormatter(params) {
  if(params.data.end_lat) return params.data.end_lat + ', ' + params.data.end_lng
  return '-';
};



</script>
<style>
.card-toolbar-date{
  width: 800px!important;
}
.ag-grid-vue{
       width: 100%!important;
       height: 600px!important;
}
.ag-header-cell-label {
   justify-content: center;
}
.ag-cell-wrap-text {
      word-break: break-word;
}
</style>
