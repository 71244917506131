<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Loss Detail</h3>
      <div class="card-toolbar">
          <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
          >
            <template v-slot:button-content>
              <i class="ki ki-bold-more-hor"></i>
            </template>
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text tag="div" class="navi-item">
                <a class="navi-link" v-if="currentUserAccountInfo && currentUserAccountInfo.teamwork_url && $parent.project.id" :href="currentUserAccountInfo.teamwork_url+'app/projects/'+$parent.project.id+'/overview/summary'" target="_blank">
                  <span class="navi-text">Open Project In Teamwork</span>
                </a>
              </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-separator my-3"></b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" v-if="$parent.project.loss_full_location" :href="'https://www.google.com/maps/place/'+$parent.project.loss_full_location" target="_blank">
          <span class="navi-text">Open in Google Map</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
       <a class="navi-link">
          <span class="navi-text">-</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
      </div>

    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template >
        <div class="d-flex flex-column font-size-base font-weight-bold mb-3">
            <span class="text-dark mb-1">
              Loss Date
            </span>
            <span class="text-muted">
              {{ $parent.project.loss_date}}
            </span>
          </div>
      </template>
      <template >
        <div class="d-flex flex-column font-size-base font-weight-bold mb-3">
            <span class="text-dark mb-1">
              Client Detail
            </span>
            <span class="text-muted" v-if="$parent.project.client_address">
              Mr. {{ $parent.project['custom_field_1261']['value'] }} <br/>
              {{ $parent.project.client_name }} <br/>
              {{ $parent.project.client_address }} <br/>
              {{ $parent.project.client_city}}, {{ $parent.project.client_state}} {{ $parent.project.client_zip }}  <br/>
            </span>
            <span class="text-danger" v-else>
              We can't find the client information in the client record. <br> Please create a client on clients table.
            </span>
          </div>
      </template>
      <br/>
       <template >
        <div class="d-flex flex-column font-size-base font-weight-bold mb-3">
            <span class="text-dark mb-1">
              Loss Detail
            </span>
            <span class="text-muted">
              {{ $parent.project.loss_address }} <br/>
              {{ $parent.project.loss_city}}, {{ $parent.project.loss_state}} {{ $parent.project.loss_zip }}  <br/>
              Claim Number: {{ $parent.project['custom_field_948']['value'] }}
            </span>
          </div>
      </template>
      <br/>
      <template >
        <div class="d-flex flex-column font-size-base font-weight-bold mb-3">
            <span class="text-dark mb-1">
              Loss County
            </span>
            <span class="text-muted">
              {{ $parent.project.loss_county}}
            </span>
          </div>
      </template>

       <template >
        <div class="d-flex flex-column font-size-base font-weight-bold mb-3">
            <span class="text-dark mb-1">
              Loss Position
            </span>
            <span class="text-muted">
              {{ $parent.project.loss_lat}} {{ $parent.project.loss_lng}}
            </span>
          </div>
      </template>

    </div>
  </div>
    <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Project Overview</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in overview">
        <!--begin::Item-->
        <div class="d-flex flex-column font-size-base font-weight-bold mb-3">
            <span class="text-dark mb-1">
              {{ item.name }}
            </span>
            <span class="text-muted">
              {{ item.value }}
            </span>
          </div>

        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "project-overview-widget",
  data() {
    let project = this.$parent.project;
    let overview = [
        { name: "Project Title: ",  value: project.name },
        { name: "Project Owner: ",  value: project['project_owner_name'] },
        { name: "Description: ", value: project['description']},
        { name: "Category: ", value: project['project_category_name'] },
      ];
    Object.keys(project).forEach(function(key){
      if(key.includes('custom') && project[key]['value']){
        overview.push({
          name: project[key]['name'],
          value: project[key]['value'] ? project[key]['value'] : 'Not Set',
        })
      }
    });
    return {
      overview :  overview
    }
  },
  methods: {
    downloadDoc() {
      this.$store
          .dispatch(GENERATE_DOC, {project_id : this.$parent.project.id})
          .then((data) => {
            if(data.type == 'application/json'){
             const reader = new FileReader();
             reader.onload = function(event) {
               const text = event.target.result;
               const resultJson = JSON.parse(text);
                Swal.fire({
                  title: "Evocation",
                  text: resultJson.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary"
                });
             };
             reader.readAsText(data);
            }
            else{
              debugger
                const url = URL.createObjectURL(new Blob([data], {
                  type: 'application/vnd.ms-word'
                }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', this.$parent.project.name + '.docx')
              document.body.appendChild(link)
              link.click()
            }
          })
    }
  },
  components: {
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUserAccountInfo"])
  }
};
</script>
