<template>
  <div class="card card-custom  gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Generate Doc </span>
      </h3>
      <div class="card-toolbar" >
        <div class="d-flex">
          <button
              v-if = "status"
              @click="downloadDoc()"
              class="btn btn-primary font-weight-bold text-uppercase"
          >
            Generate
          </button>
          <b-button variant="primary" disabled v-else>
            <b-spinner small type="grow"></b-spinner>
            Generating...
          </b-button>
        </div>
      </div>
    </div>
    <div  class="card-body pt-0 pb-6">
      <b-row>
        <b-col cols="5">
           <b-card
               header="Input Information"
           >
             <b-card-body>
               <v-row>
                 <v-col cols="12" >
                   <label>Home Owner Name:</label>
                   <b-form-input v-model="formData.Homeowner_name" placeholder="Home Owner Name" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Sex Of Home Owner:</label>
                   <b-form-select
                       v-model="formData.Sex_of_homeowner"
                       placeholder="Sex Of Home Owner"
                       :options="{ '': '-', 'Mr': 'Mr', 'Mrs': 'Mrs' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Age of Roof Covering (ex: 10 years):</label>
                   <b-form-input
                       type="number"
                       min="0"
                       step="1"
                       v-model="formData.Age_of_roof_covering"
                       placeholder="Age of Roof Covering"
                       class="modal-input"
                   ></b-form-input>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Number of Stories (ex: one story or two story):</label>
                   <b-form-input v-model="formData.Number_of_stories" placeholder="Number of Stories" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>


               <v-row>
                 <v-col cols="12" >
                   <label>Family Style:</label>
                   <b-form-select
                       v-model="formData.Faimly_style"
                       placeholder="Family Style"
                       :options="{ '': '-', 'single family': 'single family', 'multi family': 'multi family' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Screen Enclosure:</label>
                   <b-form-select
                       v-model="formData.Screen_enclosure"
                       placeholder="Screen Enclosure"
                       :options="{ '': '-', 'yes': 'yes', 'no': 'no' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Swimminig Pool:</label>
                   <b-form-select
                       v-model="formData.Swimming_pool"
                       placeholder="Swimminig Pool"
                       :options="{ '': '-', 'yes': 'yes', 'no': 'no' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Pool Enclosure:</label>
                   <b-form-select
                       v-model="formData.Pool_enclosure"
                       placeholder="Pool Enclosure"
                       :options="{ '': '-', 'yes': 'yes', 'no': 'no' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>


                <v-row>
                 <v-col cols="12" >
                   <label>Pool Enclosure Location(ex: rear elevation):</label>
                   <b-form-input v-model="formData.Pool_enclosure_location" placeholder="Pool Enclosure Location" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Garage:</label>
                   <b-form-select
                       v-model="formData.Garage"
                       placeholder="Garage"
                       :options="{ '': '-', 'yes': 'yes', 'no': 'no' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>

             </b-card-body>
           </b-card>
        </b-col>
        <b-col cols="5" offset="1">
           <b-card
               header="Input Information"
           >
             <b-card-body>
                <v-row>
                 <v-col cols="12" >
                   <label>Garage Location(ex: front elevation):</label>
                   <b-form-input v-model="formData.Garage_location" placeholder="Garage Location" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Exterior Wall Cladding(ex: concrete masonry units):</label>
                   <b-form-input v-model="formData.Exterior_wall_cladding" placeholder="Exterior Wall Cladding" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Wall Cladding(ex: cemetitious stucco):</label>
                   <b-form-input v-model="formData.Wall_cladding" placeholder="Wall Cladding" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Roof Construction(ex: frame Roof_construction):</label>
                   <b-form-input v-model="formData.Roof_construction" placeholder="Roof Construction" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Type Of Roof Covering:</label>
                   <b-form-select
                       v-model="formData.Type_of_roof_covering"
                       placeholder="Type Of Roof Covering"
                       :options="{ '': '-', 'asphalt shingles': 'asphalt shingles', 'tiles': 'tiles' }"
                       class="modal-input"
                   ></b-form-select>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Property Appraisal Record(ex: property appraiser department):</label>
                   <b-form-input
                       v-model="formData.Property_appraisal_record"
                       placeholder="Property Appraisal Record"
                       class="modal-input"
                   ></b-form-input>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Year Built (ex: 2009):</label>
                   <b-form-input
                       type="number"
                       min="1950"
                       step="2030"
                       v-model="formData.Year_built"
                       placeholder="Year Built"
                       class="modal-input"
                   ></b-form-input>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Structure Faces:</label>
                   <b-form-input
                       v-model="formData.Structure_faces"
                       placeholder="Structure Faces"
                       class="modal-input"
                   ></b-form-input>
                 </v-col>
               </v-row>

                <v-row>
                 <v-col cols="12" >
                   <label>Current Owner Name:</label>
                   <b-form-input v-model="formData.Current_Owner" placeholder="Current Owner Name" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

               <v-row>
                 <v-col cols="12" >
                   <label>Owned Since:</label>
                   <b-form-input v-model="formData.Owned_Since" placeholder="Owned Since" class="modal-input"></b-form-input>
                 </v-col>
               </v-row>

             </b-card-body>
           </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";


import {
  GET_GENERATE_DOC_DATA, GENERATE_DOC
} from "../../../core/services/store/project.module";

import Swal from "sweetalert2";
import StormOpenRenderer from "@/view/content/widgets/datatable/Render/StormOpenRenderer";

export default {
  name: "Weather-widget",
  data() {
    return {
     formData:{
        project_id: this.$parent.$parent.$parent.project.id,
        Homeowner_name: null,
        Sex_of_homeowner: '',
        Age_of_roof_covering: '',
        Number_of_stories: '',
        Faimly_style: '',
        Screen_enclosure: '',
        Swimming_pool: '',
        Pool_enclosure: '',
        Pool_enclosure_location: '',
        Garage: '',
        Garage_location: '',
        Exterior_wall_cladding: '',
        Wall_cladding: '',
        Roof_construction: '',
        Type_of_roof_covering: '',
        Property_appraisal_record: '',
        Year_built: '',
        Structure_faces: '',
        Current_Owner: '',
        Owned_Since: '',
     },
     project: this.$parent.$parent.$parent.project,
     path : null,
     status: true
    };
  },
  components: {
  },
  methods: {
    downloadDoc() {
      this.status = false;
      this.formData.project_id =  this.project.id;
      this.$store
          .dispatch(GENERATE_DOC, this.formData)
          .then((data) => {
            this.status = true;
            if(data.type == 'application/json'){
             const reader = new FileReader();
             reader.onload = function(event) {
               const text = event.target.result;
               const resultJson = JSON.parse(text);
                Swal.fire({
                  title: "Evocation",
                  text: resultJson.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary"
                });
             };
             reader.readAsText(data);
            }
            else{
                const url = URL.createObjectURL(new Blob([data], {
                  type: 'application/vnd.ms-word'
                }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', this.project.name + '.docx')
              document.body.appendChild(link)
              link.click()
            }
          })
    },
    getData(){
      this.formData.project_id = this.project.project_id;
      this.$store
          .dispatch(GET_GENERATE_DOC_DATA, this.formData)
          .then((data) => {
            if (data.status){
              this.formData = data.data;
            }
            else {

            }
          });
    },
  },
  beforeMount() {
  },
  mounted() {

  },
  created() {
    this.getData()
  },
   watch: {
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  }
};



</script>
<style>
iframe{
  display: block;
  overflow: hidden;
  width: 100%;
  height: 800px;
}
</style>
