<template>
  <div class="card card-custom  gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> BuildFax </span>
      </h3>
      <div class="card-toolbar" >
        <div class="d-flex">
          <div class="w-100"></div>
          <button
              v-if = "status"
              @click="getData()"
              class="btn btn-primary font-weight-bold text-uppercase"
          >
            Run
          </button>
          <b-button variant="primary" disabled v-else>
            <b-spinner small type="grow"></b-spinner>
            Processing...
          </b-button>
        </div>
      </div>
    </div>
    <div  class="card-body pt-0 pb-3">
        <iframe v-if="path" :src="'/static/pdfs/' + path" ></iframe>
<!--        <iframe v-if="path" :src="'http://localhost:8000/static/pdfs/BuildFaxReport_20230814180409403497-3Q5PGR-631267119.pdf'" ></iframe>-->
      </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";


import {
  GET_BUILDFAX_DATA,
} from "../../../core/services/store/project.module";
import Swal from "sweetalert2";
import StormOpenRenderer from "@/view/content/widgets/datatable/Render/StormOpenRenderer";

export default {
  name: "Weather-widget",
  data() {
    return {
     formData:{
        project_id: null,
     },
     project: this.$parent.$parent.$parent.project,
     path : null,
     status: true
    };
  },
  components: {
  },
  methods: {
    getData(){
      this.status = false;
      this.formData.project_id = this.project.project_id;
      this.$store
          .dispatch(GET_BUILDFAX_DATA, this.formData)
          .then((data) => {
            this.status = true;
            if (data.status){
              this.path = data.data;
            }
            else {

            }
          });
    },
  },
  beforeMount() {
  },
  mounted() {

  },
  created() {
    this.getData()
  },
   watch: {
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  }
};



</script>
<style>
iframe{
  display: block;
  overflow: hidden;
  width: 100%;
  height: 800px;
}
</style>
